import { useEffect } from "react";
import PageLayout from "../components/PageLayout/PageLayout";
import PlasmicHook from "../components/PageLayout/PlasmicHook";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import {
  GET_ALL_PAGES,
  GET_ALL_SLUGS,
  GET_INDIVIDUAL_PAGE,
  GET_HOME_PAGE,
} from "../graphql/pages";
import NavHook from "../components/Nav/NavHook";

const client = new ApolloClient({
  uri: process.env.STRAPI_GRAPHQL_URL,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export default function Page({ locale, head, navigation, body, footer, slug }) {
  useEffect(() => {
    window?.rudderanalytics?.page();
  }, []);
  return (
    <PageLayout
      head={head}
      locale={locale}
      navigation={navigation}
      body={body}
      footer={footer}
      slug={slug}
    />
  );
}

export async function getStaticPaths({ locale }) {
  const pages = await client.query({
    query: GET_ALL_PAGES,
    variables: {
      locale: locale === "es" ? locale : "fr-FR",
    },
  });
  const { data } = await client.query({
    query: GET_ALL_SLUGS,
    variables: {
      pageSize: parseInt(pages.data.pages.meta.pagination.total),
      locale: locale === "es" ? locale : "fr-FR",
    },
  });

  const paths = data.pages.data.map((page) => {
    //render the index route if the page is tagged as home
    if (page?.attributes?.slug === "home") {
      return {
        params: {
          slug: [],
        },
      };
    }
    return {
      params: {
        slug: [page.attributes.slug],
      },
    };
  });

  return {
    paths,
    fallback: "blocking",
  };
}
export const getStaticProps = async ({ params, locale }) => {
  const page = await getPage(params, locale);
  const slug = params?.slug ? params?.slug : [];

  //we might want to still render the nav and footer in 404
  if (!page) {
    return {
      notFound: true,
    };
  }

  //define the head data
  const head = {
    title: page?.seo?.metaTitle || "Bene Bono",
    og: {
      title: page?.seo?.metaTitle || "Bene Bono",
      siteName: "Bene Bono",
      url: `benebono.${locale}`,
      description: page?.seo?.metaDescription || "",
      image: page?.seo?.metaImage?.data?.attributes.url || "",
    },
  };

  //define the navigation data
  const mainNav = await NavHook(locale, page?.header?.data?.attributes.name);
  const navigation = {
    links: mainNav,
    name: page?.header?.data?.attributes.name || null, //return navigation's name to be used in navigation component logic (spain/catalan flag), should be done in a better way (multiple nav components or component props)
  };

  //define the body data
  const plasmic = await PlasmicHook(page.plasmicPage, locale);
  if (!plasmic) {
    throw new Error("No Plasmic design found");
  }
  const body = {
    plasmic,
  };

  //define the footer data
  const footerNav = await NavHook(locale, page?.footer?.data?.attributes.name);
  const footer = {
    links: footerNav,
  };

  return {
    props: {
      locale,
      head,
      navigation,
      body,
      footer,
      slug,
    },
    revalidate: 1200,
  };
};

async function getPage(params, locale) {
  const slug = params?.slug ? params.slug : [];
  let res;

  //nested path are not supported yet
  if (slug.length > 1) {
    return undefined;
  }

  //if we have a slug get the corresponding page
  if (slug.length === 1) {
    res = await client.query({
      query: GET_INDIVIDUAL_PAGE,
      variables: {
        slugUrl: slug[0],
        locale: locale === "es" ? locale : "fr-FR",
      },
    });
  } else {
    //else get the home page
    res = await client.query({
      query: GET_HOME_PAGE,
      variables: {
        locale: locale === "es" ? locale : "fr-FR",
      },
    });
  }

  let data =
    res?.data?.pages?.data.length > 0
      ? res.data.pages.data[0].attributes
      : undefined;
  return data;
}
