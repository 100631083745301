import React, { useEffect, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import Nav from "../Nav/Nav";
import PlasmicWrapper from "./PlasmicWrapper";
import { useRouter } from "next/router";
import Footer from "../Footer/Footer";
import useStorage from "../../utils/helpers/useStorage";
import PromotionBanner from "../PromotionBanner/PromotionBanner";

export default function PageLayout({
	locale,
	head,
	navigation,
	body,
	footer,
	slug,
}) {
	const router = useRouter();

	const { setItem, getItem } = useStorage();
	const [promotionCode, setpromotionCode] = useState();
	let promotionCodeData = [
		{
			pc: "promotion_code",
			message: {
				main: "message",
				cta: "cta",
				basket: "message",
			},
			amount: 0,
		},
	];

	useEffect(() => {
		//when we have a promotion code in as a query param
		if (router.query.pc) {
			let pc = router.query.pc;

			// make sure it's a valid one
			let promotionCodeList = promotionCodeData.filter(
				(data) => data.pc === pc
			);

			if (promotionCodeList.length > 0) {
				let promotionCode = promotionCodeList[0];
				setItem("pc", JSON.stringify(promotionCode), "session");
			}
		}

		let sessionPromotionCode = getItem("pc", "session");
		if (sessionPromotionCode) {
			setpromotionCode(JSON.parse(sessionPromotionCode));
		}
	}, [router.query]);

	return (
		<>
			<HeadCompononent title={head?.title} og={head?.og} slug={slug} />
			{navigation.links != null && (
				<Nav links={navigation.links} name={navigation.name} />
			)}
			{promotionCode && (
				<PromotionBanner
					main_message={promotionCode.message.main}
					cta_message={promotionCode.message.cta}
					has_link={true}
				/>
			)}
			<PlasmicWrapper
				plasmicData={body.plasmic.plasmicData}
				queryCache={body.plasmic.queryCache}
				locale={locale}
				query={router.query}
			/>
			{footer.links != null && <Footer links={footer.links} />}
		</>
	);
}

const HeadCompononent = ({ title, og, slug }) => {
	//should we render meta tag only if they have value ?
	//should we force values in strapi ?
	return (
		<Head>
			<title>{title}</title>
			<meta property="og:title" content={og?.title} />
			<meta property="og:site_name" content={og?.siteName} />
			<meta property="og:url" content={og?.url} />
			<meta property="og:description" content={og?.description} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={og?.image} />
			{process.env.NODE_ENV !== "production" && (
				<meta name="robots" content="noindex,nofollow" />
			)}

			<link
				rel="alternate"
				hrefLang="fr-FR"
				href={`https://benebono.fr/${slug}`}
			/>
			<link
				rel="alternate"
				hrefLang="es-ES"
				href={`https://benebono.es/${slug}`}
			/>
		</Head>
	);
};
