import {
  PlasmicRootProvider,
  PlasmicComponent,
} from "@plasmicapp/loader-nextjs";
import { PLASMIC } from "../../plasmic-init";

const PlasmicWrapper = ({ plasmicData, queryCache, locale, query, componentProps }) => {
  const compMeta = plasmicData.entryCompMetas[0];

  return (
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      pageParams={compMeta.params}
      pageQuery={query}
      globalVariants={[
        {
          name: "Locale",
          value: locale,
        },
      ]}
    >
      <PlasmicComponent component={compMeta.displayName} componentProps={componentProps}/>
    </PlasmicRootProvider>
  );
};

export default PlasmicWrapper;